import React from "react";
import AdminMenu from "../AdminMenu";

const AdminCronicasPanel = () => {
  return (
    <>
      <AdminMenu />
      <div>Cronicas</div>
    </>
  );
};

export default AdminCronicasPanel;
