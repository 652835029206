import React from "react";
import AdminMenu from "../AdminMenu";

const AdminProdePanel = () => {
  return (
    <>
      <AdminMenu />
      <div>Prode</div>
    </>
  );
};

export default AdminProdePanel;
