import React from "react";
import AdminMenu from "../AdminMenu";

const AdminUsersPanel = () => {
  return (
    <>
      <AdminMenu />
      <div>Users</div>
    </>
  );
};

export default AdminUsersPanel;
